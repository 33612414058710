<script>
export default {
  props: ["showModal", "isOpen"],
};
</script>

<template>
  <!-- Header links -->
  <div id="navItems">
    <ul class="flex font-futura-md">
      <li class="mr-[50px]">
        <a
          href="/"
          class="py-[4.2px] tracking-wider text-[21px] text-left font-medium text-primary-light hover:text-teal-300 border-t-2 sm:border-t-0 border-primary-light"
          aria-label="Projects"
          >Work</a
        >
      </li>
      <li>
        <a
          href="/resume"
          class="py-[4.2px] tracking-wider text-[21px] text-left font-medium text-primary-light hover:text-teal-300 border-t-2 sm:border-t-0 border-primary-light"
          aria-label="About Me"
          >Resume</a
        >
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
