<script>
export default {
  name: "Home",
};
</script>

<template>
  <div>
    <div
      class="font-brandon-re flex flex-col w-full sm:justify-center items-center sm:flex-row mt-[50px] px-[17px]"
    >
      <p>Seeking 2024 Fall Software Engineer Internship</p>
    </div>
  </div>
</template>

<style scoped></style>
