<script>
export default {
  data: () => {
    return {
      copyrightDate: new Date().getFullYear(),
      comment: "All Rights Reserved",
    };
  },
};
</script>

<template>
  <div class="font-brandon-re text-lg text-ternary-dark">
    &copy; {{ copyrightDate }}
    <a>
      {{ comment }}
    </a>
  </div>
</template>

<style lang="scss" scoped></style>
