<script>
import feather from "feather-icons";
import FooterCopyright from "./FooterCopyright.vue";

export default {
  components: { FooterCopyright },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      socials: [
        {
          id: 1,
          name: "LinkedIn",
          icon: "linkedin",
          url: "https://www.linkedin.com/in/kzhengg",
        },
        {
          id: 2,
          name: "Github",
          icon: "github",
          url: "https://github.com/kzhengg",
        },
      ],
    };
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
};
</script>

<template>
  <div class="flex mt-[71px] justify-between text-md">
    <!-- Footer copyright -->
    <FooterCopyright />
    <!-- Footer social links -->
    <ul class="flex gap-4">
      <a
        v-for="social in socials"
        :key="social.id"
        :href="social.url"
        target="__blank"
        class="flex justify-center items-center text-gray-400 hover:text-ternary-light cursor-pointer rounded-full bg-ternary-light hover:bg-gray-100 shadow-sm p-4 duration-500"
      >
        <i :data-feather="social.icon" class="w-5 sm:w-8 h-5 sm:h-8"></i>
      </a>
    </ul>
  </div>
</template>

<style scoped></style>
