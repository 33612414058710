<script>
export default {
  props: ["project"],
};
</script>

<template>
  <router-link
    :to="`/category/${project.id}`"
    class="cursor-pointer mb-10 sm:mb-0"
    :aria-label="`${project.title}`"
  >
    <span
      class="font-brandon-re text-[18px] text-[#444141] font-normal not-italic cursor-pointer mb-10 sm:mb-0"
      :aria-label="`${project.title}`"
    >
      <div
        class="hover:shadow-xl items-center border-none w-[335px] h-[335px] bg-cover bg-center"
        :style="{ backgroundImage: `URL(${project.img})` }"
      ></div>
      <div class="text-center px-4 py-6">
        <p>
          {{ project.title }}
        </p>
      </div>
    </span>
  </router-link>
</template>

<style lang="scss" scoped></style>
