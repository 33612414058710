<script>
import AppHeaderLinks from "./AppHeaderLinks.vue";

export default {
  components: {
    AppHeaderLinks,
  },
};
</script>

<template>
  <!-- Header start -->
  <div
    class="flex text-[42px] justify-between z-10 mb-10 max-w-screen-lg xl:max-w-screen-xl mx-auto"
  >
    <!-- Header logos -->
    <div class="w-auto">
      <a href="/">Kevin Zheng </a>
    </div>

    <!-- Header links -->
    <AppHeaderLinks :showModal="showModal" />
  </div>
</template>

<style scoped></style>
