// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const categories = [
  {
    id: 1,
    title: "OMERS",
    description:
      "A selection of work I did while leading the development Growth Equity Porfolio Management software.",
    technologies: [
      "Vite",
      "Vue 3",
      "TypeScript",
      "Python Flask",
      "PostgreSQL",
      "CosmosDB",
    ],
    projectDetails: [
      {
        id: 1,
        details: "PowerBI API",
        img: require("@/assets/images/pbisandbox.png"),
        note: "*Above is a sandbox photo, actual report is confidential",
      },
      {
        id: 2,
        details: "Sofware Migration, CICD Pipeline",
        img: require("@/assets/images/cicd.png"),
        note: "*Above is an example yaml file pipeline, actual content is confidential",
      },
      {
        id: 3,
        details: "Web scraping using Python",
        img: require("@/assets/images/webscrape.png"),
        note: "*Above are some example date scraped from Glassdoor, does not reflect implemented frontend",
      },
      {
        id: 4,
        details: "Data Aggregation",
        img: require("@/assets/images/affinity.png"),
        note: "*Consolidated data into one panel, from enterprise partners affinity CRM and Crunchbase API",
      },
    ],
    img: require("@/assets/images/dashboard.jpg"),
  },
  {
    id: 2,
    title: "City of Toronto",
    description:
      "A selection of work I did for D&A MOVE team while I was working to achieve Toronto's Vision Zero Plan.",
    technologies: ["Vue 2", "JavaScript", "Node.js", "Postgres", "Jest"],
    projectDetails: [
      {
        id: 1,
        details: "User client form functionalities",
        img: require("@/assets/images/form.png"),
      },
      {
        id: 2,
        details: "OpenMapBoxGL study location search functionalities",
        img: require("@/assets/images/search.png"),
      },
      {
        id: 3,
        details: "Frontend unit testing",
        img: require("@/assets/images/jest.png"),
        note: "The rest of the tests can be found here: https://github.com/kzhengg/MOVE/blob/master/tests/jest/api/StudyRequestController.spec.js",
      },
      {
        id: 4,
        details: "Refactoring large code base",
        img: require("@/assets/images/contributions.png"),
        note: "See contributions here: https://github.com/kzhengg/MOVE/commits/master/?author=kzhengg&before=b7d5a2244a746fa369faaf0ca8cfa715d22f88cd+35",
      },
    ],
    img: require("@/assets/images/traffic.jpg"),
  },
  {
    id: 3,
    title: "Web Applications",
    description: "A selection of web applications I created.",
    category: "Full Stack Application",
    projectDetails: [
      {
        id: 1,
        details: "Web Music Player",
        img: require("@/assets/images/spotify.png"),
        note: "Technologies: Next.js, Typescript, Supabase, Postgres, StripeAPI",
      },
      {
        id: 2,
        details: "Game of Life",
        img: require("@/assets/images/gol.png"),
        note: "Technologies: React.js, React hooks, localstorage",
      },
      {
        id: 3,
        details: "Cafe Club",
        img: require("@/assets/images/cafe.png"),
        note: "Technologies: Next.js, Typescript, Tailwind.js, Express.js, Socket.io, NextAuth, Firebase",
      },
      {
        id: 4,
        details: "Task Tracker",
        img: require("@/assets/images/todo.png"),
      },
      {
        id: 5,
        details: "Script Archive",
        img: require("@/assets/images/snippet.png"),
      },
    ],
    img: require("@/assets/images/web.jpg"),
  },
  {
    id: 4,
    title: "Python Development",
    description:
      "A selection of work that shows my backend development skills.",
    category: "Static Web Application",
    projectDetails: [
      {
        id: 1,
        details: "Come back later to see updates...",
      },
    ],
    img: require("@/assets/images/backend.jpg"),
  },
  {
    id: 5,
    title: "Data Visualization",
    description: "A selection of my data visualization work.",
    category: "Internship Projects",
    projectDetails: [
      {
        id: 1,
        details: "Come back later to see updates...",
      },
      // "Water Arrears Budgeting", "Matplotlib Projects"
    ],
    img: require("@/assets/images/data.jpg"),
  },
  {
    id: 6,
    title: "Machine Learning",
    description: "A selection of my machine learning work.",
    category: "Internship Projects",
    projectDetails: [
      {
        id: 1,
        details: "Come back later to see updates...",
      },
    ],
    img: require("@/assets/images/ml.jpg"),
  },
  {
    id: 7,
    title: "Miscellaneous Scripts",
    description: "A selection of various useful scripts.",
    category: "Python Scripts",
    projectDetails: [
      {
        id: 1,
        details: "Wifi Password Retrieval",
        note: "See script here: https://github.com/kzhengg/wifipwscript",
      },
      {
        id: 2,
        details: "File Organizer",
        note: "See script here: https://github.com/kzhengg/file-sorter-shutil",
      },
      {
        id: 3,
        details: "Python Spotify Playlist Creator",
        note: "See script here: https://github.com/kzhengg/Automated-Spotify-Playlist",
      },
    ],
    img: require("@/assets/images/link.jpg"),
  },
];

export default categories;
